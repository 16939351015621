<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Configuração de Status de Pedidos para Notificação</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Status Pedido</label>
                    <v-combobox
                        v-model="pedidoNotificacaoEmail.statusSelected"
                        :items="listStatus"
                        :rules=[validations.required]
                        item-text="statusDescricao"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Descrição do Status que aparecerá para o cliente</label>
                    <v-text-field
                        v-model="pedidoNotificacaoEmail.descricaoStatusCliente" 
                        :rules=[validations.required]
                        single-line
                        outlined
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="4"
                    md="4"
                    sm="12"
                >
                    <label>Indique os perfis que serão notificados</label>
                    <v-combobox
                        v-model="pedidoNotificacaoEmail.perfilSelected"
                        :items="listPerfil"
                        :rules=[validations.required]
                        multiple
                        item-text="nome"
                        item-value="id"
                        clearable
                        outlined
                    ></v-combobox>
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Mensagem que irá aparecer para o cliente no e-mail</label>
                    <v-textarea
                        v-model="pedidoNotificacaoEmail.mensagemStatusCliente"
                        single-line
                        rows="2"
                        row-height="15"
                        outlined
                    >
                    </v-textarea>
                </v-col>
            </v-row>

            <ActionButtons
                :request="pedidoNotificacaoEmail"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons
        },

        data: () => ({

            validForm: true,

            pedidoNotificacaoEmail: {
                id: 0,
                statusSelected: null,
                perfilSelected: null,
                status: 0,
                listIDPerfil: "",
                descricaoStatusCliente: "",
                mensagemStatusCliente: ""
            },

            listStatus: [],
            listPerfil: [],

            loading: false,
            
            validations: {
                required: required,
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("pedidoNotificacaoEmailModule/GetById", this.id);

                    if (response.success) {
                        this.pedidoNotificacaoEmail = response.result;
                        this.pedidoNotificacaoEmail.statusSelected = {
                            id: this.pedidoNotificacaoEmail.status,
                            statusDescricao: this.pedidoNotificacaoEmail.statusDescricao
                        }
                    }
                }
            },

            async getLists() {
                let listStatus = await this.$store.dispatch("pedidoModule/ListStatus");
                this.listStatus = listStatus.filter(sts => sts.id >= 1);
                
                this.listPerfil = await this.$store.dispatch("perfilModule/List");
            },

            async cancel() {
                this.$router.push({ path: "/pedido/pedidoNotificacaoEmailList" });
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", this.$t('aviso'), this.$t('inconsistenciaFormulario'));
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.pedidoNotificacaoEmail.id = this.id;
                    
                    if (this.pedidoNotificacaoEmail.statusSelected != null && this.pedidoNotificacaoEmail.statusSelected != undefined) {
                        this.pedidoNotificacaoEmail.status = this.pedidoNotificacaoEmail.statusSelected.id;
                    }
                    
                    let listIDPerfil = ""
                    if (this.pedidoNotificacaoEmail.perfilSelected != null && this.pedidoNotificacaoEmail.perfilSelected != undefined) {
                        this.pedidoNotificacaoEmail.perfilSelected.forEach(itemPerfil => {
                            listIDPerfil += `${itemPerfil.id};`
                        });
                    }
                    this.pedidoNotificacaoEmail.listIDPerfil = listIDPerfil;


                    const result = await this.$store.dispatch("pedidoNotificacaoEmailModule/CreateUpdate", this.pedidoNotificacaoEmail);

                    if (result.success === true) {
                        this.showToast("success", this.$t('sucesso'), result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", this.$t('aviso'), result.message);
                    }

                    this.loading = false;
                    this.hideLoading();
                        
                }
            }
        },

        created() {
            this.showLoading();

            this.getLists();
            this.getRegister();

            this.hideLoading();
        }
    })
</script>